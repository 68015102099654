import React, { Component, useEffect } from "react"
import Box from "@mui/material/Box"
import DataTable from "react-data-table-component"
import { Column } from "primereact/column"
import { useState } from "react"
import { GetBetsPerAgent, GetBets } from "../../services/AcceptedBetsServices"
import Button from "@mui/material/Button"
import Modal from "react-bootstrap/Modal"
import { useGlobalState, setGlobalState } from "../../helpers/state"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { Navigate } from "react-router-dom"
import { Print, PrintDisabled } from "@mui/icons-material"
import Loading from "../../img/spinner.gif"
import testimg from "../../img/stl.png"
import QRCode from "qrcode"
import ThermalPrinterEncoder from "thermal-printer-encoder"
import FormControl from "@mui/material/FormControl"
import { MdFindInPage } from "react-icons/md"
import { InputLabel, MenuItem, Select } from "@mui/material"
import { FaRotate } from "react-icons/fa6"
import * as moment from "moment"

const AcceptedBets = () => {
  let user = localStorage.getItem("usercode")
  const MySwal = withReactContent(Swal)
  var todayDate = moment().format("yyyy-MM-DD")

  let encoder = new ThermalPrinterEncoder({
    language: "esc-pos",
  })
  // Many options with Intl.DateTimeFormat
  const formatter = new Intl.DateTimeFormat("en", {
    hour12: true,
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
  })

  const games = ["ALL", "STL", "2D", "3D"]
  let time = formatter.format(new Date())

  let NEXTLINE_Char = "\u000D\u000A"
  let Feed_LINE = new TextDecoder().decode(new Uint8Array([10]))
  let Bold_str = new TextDecoder().decode(new Uint8Array([0x1b, 0x21, 0x08]))
  let normal_str = new TextDecoder().decode(new Uint8Array([0x1b, 0x21, 0x00]))

  const [printCharacteristic2, setPrintCharacteristic2] = useGlobalState(
    "printCharacteristic"
  )
  const [acceptedbets, setAcceptedbets] = useState([])
  const [betsclick, setBetsclick] = useState([])
  const [selectedtrans, setSelectedTrans] = useState(null)
  const [timeselected, setTimeSelected] = useState("ALL")
  const [gameselected, setGameSelected] = useState("ALL")
  const [grosstotal, setTotalGross] = useState(0)

  const betscolumns = [
    {
      name: "drawcode",
      selector: (row) => row.drawcode,
    },
    {
      name: "Combination",
      selector: (row) => row.combination,
    },
    {
      name: "Bet",
      selector: (row) => row.betamount,
    },
  ]

  const columns = [
    {
      name: "Trans ID",
      selector: (row) => row.transactionid,
    },
    {
      name: "Draw Date",
      selector: (row) => row.drawdate,
    },
    {
      name: "Gross",
      selector: (row) => row.totalbet,
    },
  ]

  const DrawTime = (game) => {
    var result = []
    switch (game) {
      case "3D":
      case "2D":
        result = ["2PM", "5PM", "9PM"]
        break
      case "STL":
        result = ["11AM", "4PM", "9PM"]
        break
      default:
        result = ["ALL"]
        break
    }
    return result
  }

  const LoadBets = async () => {
    let data = {
      usercode: localStorage.getItem("usercode"),
      date: todayDate,
      drawtime: timeselected,
    }
    let result = await GetBetsPerAgent(data)
    setAcceptedbets(result)
    if (result.length != 0) {
      setTotalGross(result[0].Grosstotal)
    }
  }

  const Reprint = async () => {
    try {
      //--------------------//
      if (printCharacteristic2 != null) {
        //---- Picture
        let img = new Image()
        img.src = testimg
        img.onload = async () => {
          let result = encoder
            .bold()
            .align("center")
            .image(img, 136, 136, "atkinson")
            .align("left")
            .text(
              "  LUCENT GAMING AND ENT. CORP." +
                NEXTLINE_Char +
                "        Quezon City, Ph" +
                NEXTLINE_Char +
                "--------------------------------" +
                NEXTLINE_Char +
                "      * Duplicate Copy *" +
                NEXTLINE_Char +
                NEXTLINE_Char +
                "Transaction ID:     " +
                selectedtrans +
                NEXTLINE_Char +
                "Draw Date:          " +
                todayDate +
                NEXTLINE_Char +
                "Agent Code: " +
                localStorage.getItem("usercode") +
                NEXTLINE_Char +
                "Agent Name: " +
                localStorage.getItem("agentname") +
                NEXTLINE_Char +
                `Printed: ${todayDate} ${time}` +
                NEXTLINE_Char +
                "--------------------------------" +
                NEXTLINE_Char +
                "CODE    COMB.   BET     WIN" +
                NEXTLINE_Char +
                BetsToprint() +
                "--------------------------------" +
                NEXTLINE_Char +
                TotalAmount() +
                NEXTLINE_Char +
                "--------------------------------" +
                NEXTLINE_Char
            )
            .encode()
          const buffer = result.buffer
          await writeToBluetoothCharacteristic(printCharacteristic2, buffer)
            .then(async () => {
              // console.log("Write done.")
              await test()
              MySwal.fire({
                title: <strong>Success</strong>,
                html: <i>Bets Save!</i>,
                icon: "success",
                timer: 2500,
              })
            })
            .catch((res) => {
              console.log(res)
              Swal.close()
              MySwal.fire({
                title: <strong>Error</strong>,
                html: <i>Something went wrong, Please try again!</i>,
                icon: "error",
              })
            })
        }
      } else {
        Swal.close()
        MySwal.fire({
          title: <strong>NOTICE</strong>,
          html: <i>Please connect to the printer!</i>,
          icon: "warning",
        })
      }
    } catch (error) {
      console.log(error)
      Swal.close()
      MySwal.fire({
        title: <strong>Error</strong>,
        html: <i>Something went wrong, Please try again!</i>,
        text: error,
        icon: "error",
      })
    }
  }

  const writeToBluetoothCharacteristic = async (characteristic, data) => {
    const chunkSize = 512 // Maximum allowed size
    let offset = 0

    while (offset < data.byteLength) {
      // Extract a chunk of 512 bytes (or less for the last chunk)
      const chunk = data.slice(offset, offset + chunkSize)

      try {
        await characteristic.writeValue(chunk)
        console.log(`Sent ${chunk.byteLength} bytes`)
      } catch (error) {
        console.error("Bluetooth write failed:", error)
        break // Stop if there's an error
      }

      offset += chunkSize
    }
  }

  const generateQRCode = async (text) => {
    try {
      return await QRCode.toDataURL(text, { width: 256 }) // 256px ensures good print quality
    } catch (error) {
      console.error("QR Code generation failed:", error)
      return null
    }
  }

  const test = async () => {
    const qrCodeUrl = await generateQRCode(selectedtrans)
    if (!qrCodeUrl) return
    //---- Picture
    let img = new Image()
    img.src = qrCodeUrl
    img.onload = async () => {
      let result = encoder
        .align("center")
        .image(img, 232, 232, "atkinson")
        .encode()

      const buffer = result.buffer
      await writeToBluetoothCharacteristic(printCharacteristic2, buffer).then(
        async () => {
          let footer = encoder
            .text("Thank you for supporting STL.")
            .newline()
            .text("stlqc.v1.01.05_09_24")
            .newline()
            .newline()
            .newline()
            .encode()
          printCharacteristic2.writeValue(footer)
        }
      )
    }
  }

  const BetsToprint = () => {
    let result = ""
    console.log([...betsclick])
    betsclick.forEach((row) => {
      console.log(row.bet)
      result +=
        row.drawcode +
        (row.drawcode.length == 5
          ? "     "
          : row.drawcode.length == 6
          ? "    "
          : "   ") +
        row.combination +
        (row.combination.length < 4 ? "    " : "  ") +
        row.betamount +
        (row.betamount.toString().length == 1
          ? "    "
          : row.betamount.toString().length == 2
          ? "   "
          : "  ") +
        row.winningprize +
        ".0" +
        NEXTLINE_Char
    })
    return result
  }
  const ConnectToPrinter = () => {
    if (printCharacteristic2 == null) {
      navigator.bluetooth
        .requestDevice({
          filters: [
            {
              services: ["000018f0-0000-1000-8000-00805f9b34fb"],
            },
          ],
        })
        .then((device) => {
          console.log("> Found " + device.name)
          console.log("Connecting to GATT Server...")
          Swal.fire({
            title: "Please wait!",
            text: "Connecting to Printer...",
            imageUrl: Loading,
            imageWidth: 200,
            imageHeight: 200,
            allowOutsideClick: false,
            showConfirmButton: false,
          })
          return device.gatt.connect()
        })
        .then((server) =>
          server.getPrimaryService("000018f0-0000-1000-8000-00805f9b34fb")
        )
        .then((service) =>
          service.getCharacteristic("00002af1-0000-1000-8000-00805f9b34fb")
        )
        .then((characteristic) => {
          // Cache the characteristic
          setPrintCharacteristic2(characteristic)

          Swal.close()
        })
        .catch(() => {
          setPrintCharacteristic2(null)
          Swal.close()
          MySwal.fire({
            title: <strong>Error</strong>,
            html: <i>Can't Connect to the printer, Please try again!</i>,
            icon: "error",
          })
        })
    } else {
      MySwal.fire({
        title: <strong>NOTICE</strong>,
        html: <i>Your'e already Connected!</i>,
        icon: "info",
      })
    }
  }
  const TotalAmount = () => {
    let TotalAmount = 0
    betsclick.forEach((row) => {
      TotalAmount += parseInt(row.betamount)
    })
    return "Total Amount: " + TotalAmount
  }

  const Pinterstat = () => {
    if (printCharacteristic2 != null) {
      return (
        <Button
          color="success"
          sx={{
            m: 1,
            width: "auto",
          }}
          variant="contained"
          startIcon={<Print />}
          onClick={() => ConnectToPrinter()}
        >
          Connected
        </Button>
      )
    } else {
      return (
        <Button
          color="error"
          sx={{
            m: 1,
            width: "auto",
          }}
          variant="contained"
          startIcon={<PrintDisabled />}
          onClick={() => ConnectToPrinter()}
        >
          Disconnected
        </Button>
      )
    }
  }

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {selectedtrans}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            columns={betscolumns}
            data={betsclick}
            highlightOnHover
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5]}
            fixedHeader
            pagination
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => Reprint()}>
            <i className="pi pi-print" style={{ fontSize: "2em" }}></i>
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const onClickBet = async (row) => {
    let data = {
      usercode: localStorage.getItem("usercode"),
      date: todayDate,
      transactionid: row.transactionid,
    }
    setSelectedTrans(row.transactionid)
    setBetsclick(await GetBets(data))
    // console.log(test)
    setModalShow(true)
  }
  const [modalShow, setModalShow] = React.useState(false)
  // useEffect(() => {
  //   LoadBets()
  //   console.log(acceptedbets)
  //   console.log(todayDate)
  // }, [])

  useEffect(() => {
    LoadBets()

    // Pinterstat()
    // console.log(localStorage.getItem("usercode"))
    // console.log(localStorage.getItem("agentname"))
  }, [])
  return (
    <div>
      {user == null ? <Navigate to="/" replace={true} /> : null}
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <div className="card">
        <Box
          sx={{
            display: "flex",
            width: "100%",
            "@media (min-width: 780px)": {
              width: "50%",
            },
            position: "absolute",
            justifyContent: "center",
            top: 10,
          }}
        >
          <label
            style={{
              marginLeft: "10px",
              fontWeight: "bold",
              color: "gray",
              fontSize: "20px",
            }}
          >
            Total : {grosstotal}
          </label>
        </Box>
        <Box
          sx={{
            // display: "flex",
            width: "100%",
            "@media (min-width: 780px)": {
              width: "50%",
            },
            position: "absolute",
            justifyContent: "center",
            top: 50,
          }}
        >
          <DataTable
            columns={columns}
            data={acceptedbets}
            onRowClicked={(row) => onClickBet(row)}
            highlightOnHover
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5]}
            fixedHeader
            pagination
          />
        </Box>
      </div>
      <Box
        sx={{
          // display: "flex",
          width: "100%",
          "@media (min-width: 780px)": {
            width: "50%",
          },
          display: "flex",
          position: "absolute",
          justifyContent: "center",
          top: 420,
        }}
      >
        <FormControl
          fullWidth
          sx={{
            mx: 1,
            width: "40%",
          }}
        >
          <InputLabel
            id="demo-simple-select-label"
            htmlFor="uncontrolled-native"
            size="small"
          >
            Select Time
          </InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={timeselected}
            label="Select Time"
            onChange={(e) => {
              setTimeSelected(e.target.value)
              // LoadBets(e.target.value)
            }}
          >
            {DrawTime(gameselected).map((row, i) => (
              <MenuItem key={i} value={row}>
                {row}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            mx: 1,
            width: "40%",
          }}
        >
          <InputLabel
            id="demo-simple-select-label"
            htmlFor="uncontrolled-native"
            size="small"
          >
            Select Game
          </InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={gameselected}
            label="Select Game"
            onChange={(e) => {
              setGameSelected(e.target.value)
              // LoadBets(e.target.value)
            }}
          >
            {games.map((row, i) => (
              <MenuItem key={i} value={row}>
                {row}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="success"
          sx={{
            mx: 1,
            width: "20%",
          }}
        >
          <FaRotate />
        </Button>
      </Box>
      <Box
        component="form"
        sx={{
          // mx: 2,
          // display: "flex",
          width: "100%",
          position: "absolute",
          justifyContent: "right",
          bottom: 70,
        }}
      >
        <InputLabel sx={{ mx: 2 }}>
          <b>Printer Status:</b>
          <Pinterstat />
        </InputLabel>
      </Box>
    </div>
  )
}
export default AcceptedBets
